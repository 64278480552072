import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/accounting/regulatory-reports';

export default {
    generate_report(payload) {
        return axios.post(`${RESOURCE_NAME}/generate-report`, payload)
    },
    create_report(payload) {
        return axios.post(`${RESOURCE_NAME}/create-report`, payload)
    },
    update_report(payload) {
        return axios.post(`${RESOURCE_NAME}/update-report`, payload)
    },
    delete_report(payload) {
        return axios.post(`${RESOURCE_NAME}/delete-report`, payload)
    },
    get_reports(payload) {
        return axios.get(`${RESOURCE_NAME}/get-reports`, {params: payload})
    },
    get_report(payload) {
        return axios.get(`${RESOURCE_NAME}/get-report`, {params: payload})
    },
    save_report_to_xml(payload) {
        return axios.post(`${RESOURCE_NAME}/export-report-to-xml`, payload, {responseType: 'blob'})
    }
};