<template>
  <v-container fluid class="pt-0 pb-0 align-start justify-start non-printable" fill-height>
    <v-row class="fill-height">
      <v-col cols="4" class="pb-1">
        <v-card class="fill-height">
          <v-card-text class="pt-2">
            <div class="grey darken-2 pa-4 white--text" style="border-radius: 8px">
              Перелік доступних звітів
            </div>
            <v-list dense nav>
              <v-divider></v-divider>
              <template v-for="item in reports" >
                <v-list-item dense :key="`${item.name}-list`">
                  <v-list-item-action class="my-2 mr-4">
                    <v-checkbox color="success"
                                :ripple="false"
                                v-model="item.selected"
                                @change="onReportSelect(item)"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon  size="20" class="mr-2" color="teal">mdi-chart-pie</v-icon>
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="`${item.name}-divider`"/>
              </template>

            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="8" class="fill-height pb-1">
        <v-app-bar color="white"
                   elevation="1"
                   style="align-items: center;"
                   dense class="pt-1 main-app-bar mb-3"
                   height="56"
        >
          <v-menu
              bottom
              offset-y
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2 mini-report-button"
                     v-bind="attrs"
                     v-on="on"
                     :disabled="!selected_report"
              >
                Створити звіт
              </v-btn>
            </template>

            <v-list dense nav>
              <v-list-item dense
                           v-for="item in (selected_report || {}).forms || []"
                           :key="item.name"
                           @click="create_report(item)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense v-if="!((selected_report || {}).forms || []).length">
                <v-list-item-content>
                  <v-list-item-title>
                    У даного звіту відсутні актуальні форми
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-card style="height: calc(100% - 68px)">
          <RegulatoryReportWrapper
              :modal="crud_object.show"
              :report_form="crud_object.form"
              :report_id="crud_object.id"
              :report_is_new="crud_object.is_new"
              :report_type="crud_object.report_type"
              v-if="crud_object.show"
              @closeRegularReportForm="onReportClose"
              @reportSaved="reportSaved"
              @reportDelete = 'reportDelete'
          />
          <v-card-text>
            <v-card v-for="item in items" :key="item.id"
                    @click="openReport(item)"
                class="mb-3" rounded elevation="2">
              <v-card-text style="color: #3d3d3d; font-size: .84rem" class="py-3">
                <div class="d-flex align-center flex-wrap">
                  <div style="flex: 1 1 40%; font-weight: 500">
                    {{ get_report_name(item) }}
                  </div>
                  <div style="flex: 1 1 15%; font-weight: 500" class="success--text">
                    {{ get_report_period(item) }}
                  </div>
                  <div style="flex: 1 1 15%;" class="text-center">
                    {{ get_report_type(item) }}
                  </div>
                  <div style="flex: 1 1 15%" class="text-center">
                    {{ get_report_form_name(item) }}
                  </div>
                  <div style="flex: 1 1 15%" class="text-center">
                    {{ item.create_date | formatDate }}
                  </div>
                </div>
              </v-card-text>
            </v-card>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import regulatoryAPI from "@/utils/axios/accounting/regulatory_reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {formatDate} from "@/filters";

export default {
  name: 'RegulatoryReports',
  components: {
    RegulatoryReportWrapper: () => import("@/components/RegulatoryReportWrapper.vue")
  },
  data() {
    return {
      report_types: [
        {text: 'Звітний', value: 'reportable'},
        {text: 'Звітний новий', value: 'reportable_new'},
        {text: 'Уточнюючий', value: 'clarifying'},
        {text: 'Довідковий', value: 'reference'},
      ],
      reports: [
        {
          name: 'esv_union_quarter_report',
          report_type: 'esv_declaration',
          title: 'Об’єднаний звіт ЄСВ та 1ДФ',
          forms: [
            {title: 'Форма J/F0500108 від 04.07.2022 (до 30.06.2023)', name: 'esv_union_quarter_j0500108', form_number: 'J/F0500108'},
            {title: 'Форма J/F0500108 від 02.03.2023', name: 'esv_union_quarter_j0500109', form_number: 'J/F0500109'},
          ],
          selected: false
        },
        {
          name: 'small_balance',
          report_type: 'small_balance',
          title: 'Фінансовий звіт малого підприємства',
          forms: [
            {title: 'Форма J/F0901107 від 09.07.2021', name: 'small_balance_j0901107', form_number: 'J/F0901107'},
          ],
          selected: false
        },
      ],
      selected_report: null,
      crud_object: {
        is_new: false,
        id: null,
        show: false,
        form: null,
        report_type: ''
      },
      items: []
    }
  },
  methods: {
    get_report_period(row) {
      if (row.period_type === 'month') {
        return formatDate(row.date_start, 'MMMM YY')
      }
      if (row.period_type === 'quarter') {
        let date_split = row.date_start.split('-')
        if (date_split[1] === '01') {
          return `I квартал ${date_split[0]}`
        }
        if (date_split[1] === '04') {
          return `II квартал ${date_split[0]}`
        }
        if (date_split[1] === '07') {
          return `III квартал ${date_split[0]}`
        }
        if (date_split[1] === '10') {
          return `IV квартал ${date_split[0]}`
        }
      }
      if (row.period_type === 'half_year') {
        let date_split = row.date_start.split('-')
        if (date_split[1] === '01') {
          return `I півріччя ${date_split[0]}`
        }
        if (date_split[1] === '07') {
          return `II півріччя ${date_split[0]}`
        }
      }
      if (row.period_type === 'year') {
        return formatDate(row.date_start, 'YYYY')
      }
      if (row.period_type === 'first_quarter') {
        return `I квартал ${formatDate(row.date_start, 'YYYY')}`
      }
      if (row.period_type === 'month_9') {
        return `9 місяців ${formatDate(row.date_start, 'YYYY')} р.`
      }
    },
    get_report_type(row) {
      return (this.report_types.find(i => i.value === row.report_inner_type) || {}).text
    },
    get_report_form_name(row) {
      const report = this.reports.find(i => i.report_type === row.report_type)
      let report_form_name = ''
      if (report) {
        const form = report.forms.find(i => i.name === row.report_form) || {}
        report_form_name = form.form_number
      }
      return report_form_name
    },
    get_report_name(row) {
      return this.reports.find(i => i.report_type === row.report_type).title
    },
    getReports() {
      const payload = {}
      if (this.selected_report) {
        payload.report_type = this.selected_report.report_type
      }
      regulatoryAPI.get_reports(payload)
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          })
    },
    onReportClose() {
      this.crud_object.form = null
      this.crud_object.show = false
      this.crud_object.id = null
      this.crud_object.is_new = false
      this.crud_object.report_type = ''
    },
    reportSaved(payload) {
      this.crud_object.is_new = false
      this.crud_object.id = payload.id

      const row = this.items.find(i => i.id === payload.id)
      if (row) {
        Object.keys(payload).forEach(item => {
          row[item] = payload[item]
        })
      } else {
        this.items.push(payload)
      }
    },
    reportDelete(payload) {
      this.crud_object.id = null
      this.crud_object.form = null
      this.crud_object.show = false
      this.crud_object.is_new = false
      this.crud_object.report_type = null

      const row = this.items.find(i => i.id === payload)
      if (row) {
        const idx = this.items.indexOf(row)
        this.items.splice(idx, 1)
      }
    },
    create_report(form) {
      if (this.selected_report && form) {
        this.crud_object.form = form.name
        this.crud_object.show = true
        this.crud_object.id = null
        this.crud_object.is_new = true
        this.crud_object.report_type = this.selected_report.report_type
      }
    },
    openReport(item) {
      this.crud_object.form = item.report_form
      this.crud_object.show = true
      this.crud_object.id = item.id
      this.crud_object.is_new = false
      this.crud_object.report_type = item.report_type
    },
    onReportSelect(payload) {
      this.$nextTick(() => {
        if (payload.selected) {
          this.selected_report = payload
        } else {
          this.selected_report = null
        }

        this.getReports()

        this.reports.forEach(item => {
          if (item !== payload) {
            item.selected = false
          }
        })
      })
    }
  },
  created() {
    this.getReports()
  }
}
</script>


<style scoped lang="scss">
  .main-app-bar {
    &:deep(.v-toolbar__content) {
      height: 46px !important;
    }
  }
</style>